<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">管理员姓名:</div>
            <el-input class="manage_input" type="text" size="small" v-model="paramForm.name"></el-input>
        </div>
        <div class="manage_container">
            <div class="manage">管理员身份:</div>
            <el-input class="manage_input" type="text" size="small" v-model="paramForm.identity"></el-input>
        </div>
        <div class="manage_container">
            <div class="manage">管理员账号:</div>
            <el-input class="manage_input" type="text" size="small" v-model="paramForm.account"></el-input>
        </div>
        <div class="manage">管理员权限:</div>
        <div class="checkbox_container">
            <el-checkbox-group v-model="checkList" v-for="item in permissions" :key="index"
                               class="checkList">
                <el-checkbox :label="item.title"></el-checkbox>
            </el-checkbox-group>
        </div>
        <el-button type="primary" class="confirm_button" @click="onConfirm">确认</el-button>
        <el-button type="danger" @click="onCancle">取消</el-button>

    </div>

</template>

<script>
    import md5 from 'js-md5';
    import {getData} from '@/utils/cookies'
    export default {
        name: "add",

        data() {
            return {
                checkList: [],
                permissions: [],
                password: '',
                paramForm: {
                    name: '',
                    identity: '',
                    account: '',
                    permissions: ''
                },//身份

            }
        },
        mounted() {
            console.log(getData('permissionList'));
            this.paramForm=getData('permissionList');
            this.permissions = this.paramForm.permissions;
            this.checkList=this.permissions.filter((item)=>item.has).map((item=>item.title))
        },
        methods: {
            //获取权限列表
            getpermissionList() {
                this.$store.dispatch("user/permissionList", {});
            },
            //确认修改
            onConfirm() {
                if (!this.paramForm.name){
                    this.$message.warning('请输入用户姓名!');
                }else if (!this.paramForm.identity){
                    this.$message.warning('请输入用户身份!');
                }else if (!this.paramForm.account){
                    this.$message.warning('请输入用户账号!');
                }else {
                    let list = this.$store.state.user.permission.list.map((item) => {
                        return {has: this.checkList.indexOf(item.title) >= 0, permissionId: item.id};
                    });
                    this.paramForm.password = md5(this.password);
                    this.paramForm.permissions = list;
                    this.$store.dispatch("user/updateUser", this.paramForm).then(() => {
                        this.$router.go(-1);
                    });
                }

            },
            //取消修改
            onCancle(){
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .manage {
                width: 150px;
                text-align: right;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
                margin-left: 20px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .checkbox_container {
            padding: 30px 100px 20px 100px;
            width: 100%;

            .checkList {
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 30px;
            }

        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

    }

</style>
